export default {
    addUserInfo: (state, obj) => {
      state.userInfoData = obj
    },
    updatedUserInfo: (state, obj) => {
      state.userInfoData = obj
    },    
    updatedWidth: (state, width) => {
      state.windowWidth = width
    },
    showMenuMobile: (state, show) => {
      state.menuMobile = show
    },
    updatedMenuActive: (state, status) => {
      state.navMenuActive = status
    },
    companyInfoData: (state, obj) => {
      state.companyInfoData = obj
    },
    handleReduceMenu: (state, status) => {
      state.reduceMenu = status
    }
}
  