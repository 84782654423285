'use-strict'
export  default{
    namespaced: true,
    state: {
        loadingStatus: false
    },
    mutations: {
        SET_LOADING_STATUS (state, val){
            state.loadingStatus = val
        },
    },
    actions: {
        LOADING_STATUS({commit}, data){
            commit('SET_LOADING_STATUS', data)
            return true
        },
    },
    getters: {
        GET_LOADING_STATUS: state => state.loadingStatus,
    }
}