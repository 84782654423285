'use-strict'
export  default {
    namespaced: true,
    state: {
        active: false,
        reduced: false,
        mobileMode: false,
        locked: false,
    },
    mutations: {
        SET_MENU_ACTIVE(state, value){
            state.active = value
        },
        SET_MENU_REDUCED(state, value){
            state.reduced = value
        },
        SET_MENU_MOBILE_MODE(state, value){
            state.mobileMode = value
        },
        SET_MENU_LOCKED(state, value){
            state.locked = value
        }
    },
    actions: {
        updatedMenuActive: ({commit}, status) => {
            commit('SET_MENU_ACTIVE', status)
        },

        updatedMenuReduced: ({commit}, status) => {
            commit('SET_MENU_REDUCED', status)
        },
        
        updatedMenuMobileMode: ({commit}, status) => {
            commit('SET_MENU_MOBILE_MODE', status)
        },
        
        updatedMenuLockMode: ({commit}, status) => {
            commit('SET_MENU_LOCKED', status)
        },
    },
    getters: {
        isActive: (state) => { 
            return state.active 
        },
        isReduced: (state) => { 
            return state.reduced 
        },
        isMobile: (state) => { 
            return state.mobileMode 
        },
        isLocked: (state) => {
            return state.locked
        }
    }
}