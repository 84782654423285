import Vue from 'vue'
import Vuex from 'vuex'
// import createPersistedState from "vuex-persistedstate"
// import { auth } from './auth.module';
import getters from './getters'
import state from './state'
import mutations from './mutations'
import actions from './actions'
import mainMenu from  './mainMenu'


// import auth from  './modules/Auth'
import appSetting from  './modules/AppSetting'

Vue.use(Vuex)

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    mainMenu,
    appSetting,
  },
})
