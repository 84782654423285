import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    component: () => import('../views/Layouts/Main.vue'),
    children: [
      {
        path: '/',
        name: 'RouteBooking',
        meta: {
          // authorize: [ 'admin' ],
        },
        component: () => import('../views/Bookings/Index.vue')
      },
      {
        path: '/dashboard',
        name: 'dashboardSale',
        meta: {
          // authorize: [ 'admin' ],
        },
        component: () => import('../views/Dashboard/Sales.vue')
      }, 

      {
        path: '/gerencial',
        name: 'RouteGerencial',
        meta: {
          // authorize: [ 'admin' ],
        },
        component: () => import('../views/Dashboard/General.vue')
      }, 
      {
        path: '/dashboard-2',
        name: 'dashboardCompaYears',
        meta: {
          // authorize: [ 'admin' ],
        },
        component: () => import('../views/Dashboard/YearComparison.vue')
      }, 
      {
        path: '/dashboard-3',
        name: 'dashboardCompaAgents',
        meta: {
          // authorize: [ 'admin' ],
        },
        component: () => import('../views/Dashboard/AgentComparison.vue')
      }, 
      {
        path: '/reports/tariff-non-acc',
        name: 'RouteTariffNonAcc',
        meta: {
          // authorize: [ 'admin' ],
        },
        component: () => import('../views/Tariff/NonAcc.vue')
      }, 

      {
        path: '/dashboard-4',
        name: 'dashboardCostSuplier',
        meta: {
          // authorize: [ 'admin' ],
        },
        component: () => import('../views/Dashboard/CostSupplier.vue')
      },

      {
        path: '/dashboard-5',
        name: 'dashboardCostSrv',
        meta: {
          // authorize: [ 'admin' ],
        },
        component: () => import('../views/Dashboard/CostService.vue')
      },

      {
        path: '/reports/cost-and-sale-by-service',
        name: 'RouteCostSaleforService',
        meta: {
          // authorize: [ 'admin' ],
        },
        component: () => import('../views/Bookings/CostSaleforService.vue')
      },      
      {
        path: '/reports/sales',
        name: 'RouteSales',
        meta: {
          // authorize: [ 'admin' ],
        },
        component: () => import('../views/Sales/Index.vue')
      },
      {
        path: '/reports/booking-analysis',
        name: 'RouteBookAnalysis',
        meta: {
          // authorize: [ 'admin' ],
        },
        component: () => import('../views/BookingAnalysis/Index.vue')
      },
      {
        path: '/reports/booking-financials-analysis',
        name: 'RouteBookAnalysis',
        meta: {
          // authorize: [ 'admin' ],
        },
        component: () => import('../views/BookingAnalysis/Financials.vue')
      },
      {
        path: '/reports/payment-suppliers',
        name: 'RoutePaymentSuppliers',
        meta: {
          // authorize: [ 'admin' ],
        },
        component: () => import('../views/PaymentToSuppliers/Index.vue')
      },
      {
        path: '/reports/accounts-receivable',
        name: 'RouteAccountReceivable',
        meta: {
          // authorize: [ 'admin' ],
        },
        component: () => import('../views/AccountsReceivable/Index.vue')
      },
      {
        path: '/reports/stats-locations',
        name: 'RouteStatsLocations',
        meta: {
          // authorize: [ 'admin' ],
        },
        component: () => import('../views/Reports/Stats/Locations.vue')
      },
      {
        path: '/reports/stats-serv-types',
        name: 'RouteStatsServTypes',
        meta: {
          // authorize: [ 'admin' ],
        },
        component: () => import('../views/Reports/Stats/ServicesTypes.vue')
      },
      {
        path: '/reports/stats-suppliers',
        name: 'RouteStatsSuppliers',
        meta: {
          // authorize: [ 'admin' ],
        },
        component: () => import('../views/Reports/Stats/Suppliers.vue')
      },
      {
        path: '/reports/sales-achivied',
        name: 'RouteSalesAchivied',
        meta: {
          // authorize: [ 'admin' ],
        },
        component: () => import('../views/Reports/Suppliers/SalesAchivied.vue')
      },
    ]
  },
  {
    path: '',
    component: () => import('../views/Layouts/Auth.vue'),
    children: [
      {
        path: '/login',
        name: 'Login',
        meta: {},
        component: () => import('../views/Auth/Login.vue')
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    if ( savedPosition ) {
      return savedPosition
    } else {
      const position = {} 
      if (to.hash) {
        position.selector = to.hash
        position.behavior = 'smooth'
        if ( document.querySelector(to.hash)) {
          return position
        }
        return false
      }
      return { x: 0, y: 0 }  
    }
  },
  routes
})

export default router

router.beforeEach((to, from, next) => {
  const { authorize } = to.meta;
  const currentUser = JSON.parse(localStorage.getItem('RSUSR'));
  if (authorize) {
      if (currentUser == null) {
          console.log('sss') 
          return next({ path: '/login'});
      }
      // check if route is restricted by role
      // if (authorize.length) {
      //     // role not authorised so redirect to home page
      //     return next({ path: '/' });
      // }
  }
  next();
})