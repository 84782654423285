export default {
    addUserInfo: ({ commit }, obj) => {
      commit('addUserInfo', obj)
    },
    updatedUserInfo: ({ commit }, obj) => {
      commit('updatedUserInfo', obj)
    },
    updatedWidth: ({commit}, width) => {
      commit('updatedWidth', width)
    },
    showMenuMobile: ({commit}, show) => {
      commit('showMenuMobile', show)
    },
    updatedMenuActive: ({commit}, status) => {
      commit('updatedMenuActive', status)
    },
    companyInfoData: ({commit},obj) =>{
      commit('companyInfoData', obj)
    },
    handleReduceMenu: ({commit}, status) => {
      console.log('HEEERE')
      commit('handleReduceMenu', status)
    }
}
  