<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
// import { mapActions } from 'vuex'
// import { createNamespacedHelpers } from 'vuex'
// const  { mapGetters: mapLangGetter, mapActions: mapLangActions } = createNamespacedHelpers('lang')
export default {
  async created (){
    // window.addEventListener('resize', this.handleWindowResize)
    // window.addEventListener('scroll', this.handleScroll)
  },
  // computed:{
  //   isIdle() {

	// 		return this.$store.state.idleVue.isIdle;

	// 	}
  // },
  // onIdle() {
  //   console.log('ZZZ')
  //   if (this.$store.state.auth.user !== null) {
  //     this.$store.dispatch('auth/logout');
  //     this.$router.push('/login');
  //   }
  // },
    // computed: {
    //   ...mapLangGetter(['CURRENT_LANG'])
    // },
    // watch: {
    //     CURRENT_LANG (lang) { this.$i18n.locale = lang }
    // },
    // mounted () {
    //   this.updatedWidth(window.innerWidth)
    //   this.setLanguage()
    // },
    methods: {
      // ...mapActions(['updatedWidth']),
      // // ...mapLangActions(['UPDATE_LANG']),
      // // setLanguage () {
      // //   let lang  = JSON.parse(localStorage.getItem('lang')) || "es"
      // //   this.UPDATE_LANG(lang)
      // // },        
      // handleWindowResize () {
      //   this.updatedWidth(window.innerWidth)
      // }
    }
}
</script>