import userInfoData from '../data/userInfoData'
import companyInfoData from '../data/companyInfoData'
const windowWidth     = null;
const menuMobile      = false;
const navMenuActive   = true;
const reduceMenu      = false;
export default {
  userInfoData,
  windowWidth,
  menuMobile,
  navMenuActive,
  companyInfoData,
  reduceMenu,
  name: "OLMAN",
}
